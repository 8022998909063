.channel{
    background-color: #141621;
    width: 100%;
    cursor: pointer;
    .banner{
        width: 100%;
    }
    .banner-s{
        width: 100%;
        display: none;
    }
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
    .channel{

    }
}
@media screen and (min-width: 576px) and (max-width: 992px) {
    .channel{
        .banner{
            display: none;
        }
        .banner-s{
            display: block;
        }
    }
}
@media screen and (max-width: 576px) {
    .channel{
        .banner{
            display: none;
        }
        .banner-s{
            display: block;
        }
    }
}