.aboutUs {
    background-color: #fff;

    .banner {
        position: relative;

        .banner-pic {
            width: 100%;
        }
    }

    .about {
        position: relative;
        height: 700px;

        .content-bg {
            width: 80%;
            height: 550px;
            background-color: #F5F7FA;
            position: absolute;
            top: 0;
            right: 0;
            border-radius: 0px 220px 0px 220px;
            .title {
                font-size: 48px;
                font-weight: bold;
                text-align: right;
                width: 80%;
                margin-bottom: 20px;
                margin-top: 74px;
                .title-box{
                    position: relative;
                    .box{
                        display: flex;
                        justify-content: space-between;
                        position: absolute;
                        top: 16px;
                        right: -50px;
                        width: 18px;
                        height: 18px;
                        div{
                            width: 8px;
                            height: 8px;
                        }
                        div:nth-child(1){
                            background-color: #3C3C3C;
                        }
                        div:nth-child(2){
                            background-color: #C1272D;
                        }
                    }
                }
            }

            .title-s {
                font-size: 16px;
                color: #3C3C3C;
                display: block;
                padding-right: 6px;
                text-align: right;
                width: 80%;
            }
            
        }
        .content{
            position: absolute;
            top: 148px;
            left: 0;
            width: 100%;
            display: flex;
            justify-content: space-between;
            .left{
                width: 42%;
                img{
                    width: 100%;
                }
            }
            .right{
                padding-top: 50px;
                width: 48%;
                .label{
                    font-size: 16px;
                    color: #3C3C3C;
                    position: relative;
                    margin-bottom: 76px;
                }
                .label::after{
                    content: "";
                    background-color: #C1272D;
                    width: 80px;
                    height: 1px;
                    position: absolute;
                    left: 70px;
                    top: 50%;
                    transform: translate(0, -50%);
                }

                h2 {
                    font-size: 26px;
                    margin-bottom: 46px;
                }

                p {
                    width: 360px;
                    font-size: 16px;
                    line-height: 1.25;
                    margin-bottom: 46px;
                }

                .btn {
                    width: 360px;
                    height: 66px;
                    background: #C1272D;
                    border-radius: 6px;
                    font-size: 18px;
                    color: #fff;
                    line-height: 66px;
                    text-align: center;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    position: relative;

                    img {
                        position: absolute;
                        top: 50%;
                        margin-top: -4px;
                        right: 20px;
                    }
                }
            }
        }

    }

    .cultures {
        background-image: url('./imgs/cultures-bg.jpg');
        background-size: 100% 100%;
        padding-top: 80px;
        padding-bottom: 220px;
        margin-bottom: 42px;

        .content {
            width: 1200px;
            margin: 0 auto;

            .culturesItems {
                width: 100%;

                .culturesItem {
                    display: flex;
                    justify-content: space-between;

                    .left{
                        width: 52%;
                        img{
                            width: 100%;
                        }
                    }
                    .right {
                        position: relative;
                        width: 44%;
                        padding-left: 50px;

                        .title {
                            font-size: 48px;
                            font-weight: bold;
                            position: relative;
                            .box{
                                display: flex;
                                justify-content: space-between;
                                position: absolute;
                                top: 30px;
                                left: -50px;
                                width: 18px;
                                height: 18px;
                                div{
                                    width: 8px;
                                    height: 8px;
                                }
                                div:nth-child(1){
                                    background-color: #3C3C3C;
                                }
                                div:nth-child(2){
                                    background-color: #C1272D;
                                }
                            }
                        }

                        .title-s {
                            font-size: 16px;
                            color: #3C3C3C;
                            display: block;
                            padding-right: 6px;
                        }


                        h2 {
                            margin-top: 40px;
                            font-size: 24px;
                            font-weight: bold;
                            margin-bottom: 24px;
                        }

                        p {
                            font-size: 16px;
                            line-height: 1.25;
                            margin-bottom: 40px;
                        }

                        .btn {
                            width: 360px;
                            height: 66px;
                            background: #C1272D;
                            border-radius: 6px;
                            font-size: 18px;
                            color: #fff;
                            line-height: 66px;
                            text-align: center;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            cursor: pointer;
                            position: relative;

                            img {
                                position: absolute;
                                top: 50%;
                                margin-top: -4px;
                                right: 20px;
                            }
                        }

                        .btns {
                            width: 112px;
                            height: 56px;
                            display: flex;
                            padding: 12px 0;
                            position: absolute;
                            bottom: 0px;
                            left: 50px;
                            background-color: #fff;
                            cursor: pointer;

                            .l {
                                width: 50%;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                border-right: 1px solid #E0E0E0;
                            }

                            .r {
                                width: 50%;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                            }
                        }
                    }

                    .left {}
                }
            }
        }
    }

    .map {
        margin-bottom: 140px;

        .content {
            width: 1200px;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;

            .text {
                text-align: left;
                position: relative;

                .title {
                    font-size: 48px;
                    font-weight: bold;
                    margin-bottom: 10px;
                    position: relative;
                }

                .title-s {
                    font-size: 16px;
                    color: #3C3C3C;
                }

                h3 {
                    font-size: 24px;
                    color: #C1272D;
                    margin-bottom: 60px;
                    margin-top: 67px;
                }

                p {
                    font-size: 16px;
                    display: flex;
                    align-items: center;
                    margin-bottom: 10px;

                    img {
                        margin-right: 8px;
                    }
                }

                .pic {
                    position: absolute;
                    bottom: -70px;
                    right: -122px;
                    z-index: 11;
                }
            }

            .baiduMap {
                width: 75%;
                height: 620px;
            }
        }
    }
}

// 媒体查询
@media screen and (min-width: 992px) and (max-width: 1200px) {
    .aboutUs{
        .about{
            height: 500px;
            .content-bg{
                height: 440px;
                .title{
                    margin-top: 40px;
                    width: 80%;
                    font-size: 40px;
                }
                .title-s{
                    width: 80%;
                }
            }
            .content{
                .right{
                    padding-top: 20px;
                    .label{
                        margin-bottom: 40px;
                    }
                    h2{
                        margin-bottom: 20px;
                    }
                    p{
                        margin-bottom: 30px;
                        font-size: 14px;
                    }
                }
            }
        }
        .cultures{
            padding-top: 60px;
            padding-bottom: 140px;
            .content{
                width: 90%;
                .culturesItems {
                    width: 100%;
                    .culturesItem {
                        .left{
                            
                        }
                        .right {
                            .title{
                                font-size: 32px;
                            }
                            h2{
                                margin-top: 30px;
                                margin-bottom: 20px;
                            }
                            p{
                                margin-bottom: 30px;
                            }
                            .btn{
                                width: 300px;
                                height: 50px;
                                line-height: 50px;
                            }
                        }
                    }
                }
            }
        }
        .map{
            margin-bottom: 110px;
            .content{
                width: 90%;
            }
        }
    }
}
@media screen and (min-width: 576px) and (max-width: 992px) {
    .aboutUs{
        .about{
            height: 790px;
            .content-bg{
                width: 100%;
                height: 760px;
                .title{
                    margin-top: 40px;
                    font-size: 40px;
                    width: 100%;
                    text-align: center;
                }
                .title-s{
                    width: 100%;
                    text-align: center;
                }
            }
            .content{
                flex-direction: column;
                .left{
                    width: 80%;
                    margin: 0 auto;
                }
                .right{
                    width: 80%;
                    margin: 0 auto;
                    padding-top: 20px;
                    .label{
                        margin-bottom: 40px;
                    }
                    h2{
                        margin-bottom: 20px;
                        text-align: center;
                    }
                    p{
                        width: 100%;
                        margin-bottom: 30px;
                        font-size: 14px;
                        text-align: center;
                    }
                    .btn{
                        width: 300px;
                        height: 50px;
                        margin: 0 auto;
                    }
                }
            }
        }
        .cultures{
            padding-top: 60px;
            padding-bottom: 130px;
            .content{
                width: 90%;
                .culturesItems {
                    width: 100%;
                    .culturesItem {
                        flex-direction: column;
                        .left{
                            width: 100%;
                        }
                        .right {
                            width: 100%;
                            padding-bottom: 90px;
                            text-align: center;
                            margin-bottom: 20px;
                            padding-left: 0;
                            .title{
                                font-size: 32px;
                                .box{
                                    display: none;
                                }
                            }
                            h2{
                                margin-top: 30px;
                                margin-bottom: 20px;
                            }
                            p{
                                margin-bottom: 30px;
                            }
                            .btn{
                                width: 300px;
                                height: 50px;
                                line-height: 50px;
                                margin: 0 auto;
                            }
                            .btns{
                                left: 50%;
                                transform: translate(-50%, 0);
                            }
                        }
                    }
                }
            }
        }
        .map{
            margin-bottom: 80px;
            .content{
                width: 90%;
                flex-direction: column;
                .text{
                    margin-bottom: 20px;
                    .pic{
                        right: 0;
                        bottom: 0;
                        width: 300px;
                    }
                }
                .baiduMap{
                    width: 100%;
                    height: 400px;
                }
            }
        }
    }
}
@media screen and (max-width: 576px) {
    .aboutUs{
        .about{
            height: 750px;
            .content-bg{
                width: 100%;
                height: 720px;
                .title{
                    margin-top: 40px;
                    font-size: 32px;
                    width: 100%;
                    text-align: center;
                }
                .title-s{
                    width: 100%;
                    text-align: center;
                }
            }
            .content{
                flex-direction: column;
                .left{
                    width: 80%;
                    margin: 0 auto;
                }
                .right{
                    width: 80%;
                    margin: 0 auto;
                    padding-top: 20px;
                    .label{
                        margin-bottom: 40px;
                    }
                    h2{
                        margin-bottom: 20px;
                        text-align: center;
                        font-size: 20px;
                    }
                    p{
                        width: 100%;
                        margin-bottom: 30px;
                        font-size: 14px;
                        text-align: center;
                    }
                    .btn{
                        width: 200px;
                        height: 40px;
                        margin: 0 auto;
                        font-size: 14px;
                    }
                }
            }
        }
        .cultures{
            padding-top: 60px;
            padding-bottom: 130px;
            .content{
                width: 90%;
                .culturesItems {
                    width: 100%;
                    .culturesItem {
                        flex-direction: column;
                        .left{
                            width: 100%;
                        }
                        .right {
                            width: 100%;
                            padding-bottom: 90px;
                            text-align: center;
                            margin-bottom: 20px;
                            padding-left: 0;
                            .title{
                                font-size: 32px;
                                .box{
                                    display: none;
                                }
                            }
                            h2{
                                margin-top: 30px;
                                margin-bottom: 20px;
                            }
                            p{
                                margin-bottom: 30px;
                            }
                            .btn{
                                width: 300px;
                                height: 50px;
                                line-height: 50px;
                                margin: 0 auto;
                            }
                            .btns{
                                left: 50%;
                                transform: translate(-50%, 0);
                            }
                        }
                    }
                }
            }
        }
        .map{
            margin-bottom: 60px;
            .content{
                width: 90%;
                flex-direction: column;
                .text{
                    margin-bottom: 20px;
                    .pic{
                        right: 0;
                        bottom: 80px;
                        width: 200px;
                    }
                }
                .baiduMap{
                    width: 100%;
                    height: 300px;
                }
            }
        }
    }
}