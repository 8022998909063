.details{
    .banner {
        position: relative;
        .banner-pic {
            width: 100%;
        }
    }
    .title{
        font-size: 24px;
        color: #333333;
        font-weight: bold;
        text-align: center;
        margin-bottom: 20px;
        margin-top: 30px;
    }
    .date{
        font-size: 18px;
        color: #777;
        text-align: center;
        margin-bottom: 30px;
    }
    .content{
        width: 1200px;
        margin: 0 auto; 
        padding: 50px 0;
        .pic{
            text-align: center;
            margin-bottom: 20px;
        }
        p{
          text-align: left;
          font-size: 16px;
          color: #333;
          line-height: 1.5;
          text-indent: 2em;
          margin-bottom: 40px;
        }
    }
    .btn{
      width: 1200px;
      cursor: pointer;
      margin: 0 auto;
      margin-bottom: 10px;
    }
    .btn:hover{
      color: #365ac7;
    }
}
@media screen and (min-width: 992px) and (max-width: 1200px) {
    .details{
        .content {
            width: 90%;
        }
        .btn{
            width: 90%;
        }
    }
}
@media screen and (min-width: 576px) and (max-width: 992px) {
    .details{
        .content {
            width: 90%;
        }
        .btn{
            width: 90%;
        }
    }
}
@media screen and (max-width: 576px) {
    .details{
        .content {
            width: 90%;
        }
        .btn{
            width: 90%;
        }
    }
}