.case{
    .banner{
        position: relative;
        margin-bottom: 78px;
        .banner-pic{
            width: 100%;
        }
    }
    .content{
        width: 1200px;
        margin: 0 auto;
        li{
            margin-bottom: 50px;
            display: flex;
            justify-content: space-between;
            img{
                width: 53%;
            }
            .text{
                width: 40%;
                .label{
                    color: #C1272D;
                    font-size: 16px;
                    margin-bottom: 30px;
                    .title-box{
                        position: relative;
                        .box{
                            display: flex;
                            justify-content: space-between;
                            position: absolute;
                            top: 4px;
                            left: -40px;
                            width: 18px;
                            height: 18px;
                            div{
                                width: 8px;
                                height: 8px;
                            }
                            div:nth-child(1){
                                background-color: #3C3C3C;
                            }
                            div:nth-child(2){
                                background-color: #C1272D;
                            }
                        }
                    }
                }
                h2{
                    font-size: 24px;
                    margin-bottom: 20px;
                }
                p{
                    line-height: 1.25;
                    font-size: 16px;
                    margin-bottom: 20px;
                }
                .btn{
                    width: 160px;
                    height: 46px;
                    background: #C1272D;
                    border-radius: 8px;
                    text-align: center;
                    line-height: 46px;
                    font-size: 18px;
                    color: #fff;
                    cursor: pointer;
                }
            }
        }
    }
}

// 媒体查询
@media screen and (min-width: 992px) and (max-width: 1200px) {
    .case{
        .content{
            width: 90%;
        }
    }
}
@media screen and (min-width: 576px) and (max-width: 992px) {
    .case{
        .banner{
            margin-bottom: 40px;
        }
        .content{
            width: 90%;
            li{
                display: block;
                text-align: center;
                img{
                    width: 100%;
                    margin-bottom: 20px;
                }
                .text{
                    width: 100%;
                    .btn{
                        margin: 0 auto;
                        margin-bottom: 20px;
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 576px) {
    .case{
        .banner{
            margin-bottom: 40px;
        }
        .content{
            width: 90%;
            li{
                display: block;
                text-align: center;
                img{
                    width: 100%;
                    margin-bottom: 20px;
                }
                .text{
                    width: 100%;
                    .btn{
                        margin: 0 auto;
                        margin-bottom: 20px;
                    }
                }
                
            }
        }
    }
}
