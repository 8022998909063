.home {
    .banner {
        position: relative;

        .banner-pic {
            width: 100%;
        }

    }
    .about{
        background-color: #fff;
        position: relative;
        height: 840px;
        .bg{
            width: 60%;
            height: 100%;
            position: absolute;
            top: 0;
            right: 0;
            background: #F5F7FA;
            border-radius: 0px 22px 0px 220px;
        }
        .content{
            width: 1200px;
            position: absolute;
            top: 76px;
            left: 50%;
            transform: translate(-50%,0);
            .title {
                font-size: 48px;
                font-weight: bold;
                margin-bottom: 20px;
                .title-box{
                    position: relative;
                    .box{
                        display: flex;
                        justify-content: space-between;
                        position: absolute;
                        top: 24px;
                        left: -40px;
                        width: 18px;
                        height: 18px;
                        div{
                            width: 8px;
                            height: 8px;
                        }
                        div:nth-child(1){
                            background-color: #3C3C3C;
                        }
                        div:nth-child(2){
                            background-color: #C1272D;
                        }
                    }
                }
            }

            .title-s {
                font-size: 16px;
                color: #3C3C3C;
                display: block;
                padding-right: 6px;
            }

            .text{
                width: 100%;
                display: flex;
                justify-content: space-between;
                margin-top: 78px;
                .left{
                    width: 55%;
                    img{
                        width: 100%;
                    }
                }
                .right{
                    padding-top: 48px;
                    width: 40%;
                    .label{
                        font-size: 16px;
                        color: #3C3C3C;
                        position: relative;
                        margin-bottom: 60px;
                    }
                    .label::after{
                        content: "";
                        background-color: #C1272D;
                        width: 80px;
                        height: 1px;
                        position: absolute;
                        left: 70px;
                        top: 50%;
                        transform: translate(0, -50%);
                    }

                    h2 {
                        font-size: 26px;
                        margin-bottom: 36px;
                    }

                    p {
                        width: 360px;
                        font-size: 16px;
                        line-height: 1.25;
                        margin-bottom: 46px;
                    }

                    .btn {
                        width: 360px;
                        height: 66px;
                        background: #C1272D;
                        border-radius: 6px;
                        font-size: 18px;
                        color: #fff;
                        line-height: 66px;
                        text-align: center;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                        position: relative;
                        margin-bottom: 20px;

                        img {
                            position: absolute;
                            top: 50%;
                            margin-top: -4px;
                            right: 20px;
                        }
                    }
                    .toAbout{
                        display: flex;
                        align-items: center;
                        img{
                            margin-right: 15px;
                        }
                    }
                }
            }
        }
    }

    .advantage {
        background-color: #fff;
        padding-top: 72px;
        padding-bottom: 100px;
        border-radius: 0px 220px 0 0px;

        .content {
            width: 1200px;
            margin: 0 auto;

            .title {
                font-size: 48px;
                font-weight: bold;
                margin-bottom: 20px;
                text-align: right;
                .title-box{
                    position: relative;
                    .box{
                        display: flex;
                        justify-content: space-between;
                        position: absolute;
                        top: 24px;
                        right: -40px;
                        width: 18px;
                        height: 18px;
                        div{
                            width: 8px;
                            height: 8px;
                        }
                        div:nth-child(1){
                            background-color: #3C3C3C;
                        }
                        div:nth-child(2){
                            background-color: #C1272D;
                        }
                    }
                }
            }

            .title-s {
                font-size: 16px;
                color: #3C3C3C;
                display: block;
                padding-right: 6px;
                text-align: right;
            }


            ul {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: 46px;
                margin-bottom: 60px;

                li {
                    width: 32%;
                    background: #FEEEEF;
                    box-shadow: 0px 6px 26px 0px rgba(23, 96, 229, 0.1);
                    border-radius: 20px;
                    padding: 30px 15px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .left {
                        width: 50%;
                        .douhao{
                            margin-bottom: 28px;
                        }

                        h2 {
                            font-size: 24px;
                            color: #C1272D;
                            margin-bottom: 18px;
                        }

                        p {
                            font-size: 16px;
                            line-height: 1.5;
                            margin-bottom: 30px;
                            display: -webkit-box;
                            /**对象作为伸缩盒子模型展示**/
                            -webkit-box-orient: vertical;
                            /**设置或检索伸缩盒子对象的子元素的排列方式**/
                            -webkit-line-clamp: 3;
                            /**显示的行数**/
                            overflow: hidden;
                            /**隐藏超出的内容**/
                        }

                        .btn {
                            width: 160px;
                            height: 46px;
                            background-color: #1760E5;
                            text-align: center;
                            line-height: 46px;
                            font-size: 18px;
                            cursor: pointer;
                            color: #fff;
                            border-radius: 6px;
                        }
                    }
                    .right{
                        width: 45%;
                        img{
                            width: 100%;
                        }
                    }
                }
            }
            .btn-box{
                display: flex;
                justify-content: flex-end;
                .btn {
                    width: 360px;
                    height: 66px;
                    background: #C1272D;
                    border-radius: 6px;
                    font-size: 18px;
                    color: #fff;
                    line-height: 66px;
                    text-align: center;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    position: relative;
                    margin-bottom: 20px;
    
                    img {
                        position: absolute;
                        top: 50%;
                        margin-top: -4px;
                        right: 20px;
                    }
                }
            }
        }
    }

    .case-box{
        background-image: url('./imgs/case-bg.png');
        background-repeat: no-repeat;
        background-color: #fff;
        border-radius: 0px 0 220px 0px;
        padding-bottom: 148px;
        .case {
            width: 1200px;
            margin: 0 auto;
            border-radius: 40px;
    
            .caseItems {
                width: 100%;
                padding: 80px 42px 126px 42px;
    
                .caseItem {
                    display: flex;
                    justify-content: space-between;
    
                    .left {
                        width: 30%;
                        padding-left: 40px;
    
                        .title {
                            font-size: 48px;
                            font-weight: bold;
                            margin-bottom: 20px;
                            .title-box{
                                position: relative;
                                .box{
                                    display: flex;
                                    justify-content: space-between;
                                    position: absolute;
                                    top: 24px;
                                    left: -40px;
                                    width: 18px;
                                    height: 18px;
                                    div{
                                        width: 8px;
                                        height: 8px;
                                    }
                                    div:nth-child(1){
                                        background-color: #3C3C3C;
                                    }
                                    div:nth-child(2){
                                        background-color: #C1272D;
                                    }
                                }
                            }
                        }
            
                        .title-s {
                            font-size: 16px;
                            color: #3C3C3C;
                            display: block;
                            padding-right: 6px;
                        }
    
                        h2 {
                            margin-top: 40px;
                            font-size: 24px;
                            font-weight: bold;
                            margin-bottom: 24px;
                        }
    
                        p {
                            font-size: 16px;
                            line-height: 1.25;
                            margin-bottom: 40px;
                            display: -webkit-box;
                            /**对象作为伸缩盒子模型展示**/
                            -webkit-box-orient: vertical;
                            /**设置或检索伸缩盒子对象的子元素的排列方式**/
                            -webkit-line-clamp: 2;
                            /**显示的行数**/
                            overflow: hidden;
                            /**隐藏超出的内容**/
                        }
    
                        .btns {
                            width: 100%;
                            display: flex;
                            justify-content: space-between;
    
                            .btn {
                                width: 48%;
                                height: 40px;
                                background: #C1272D;
                                border-radius: 6px;
                                font-size: 14px;
                                color: #fff;
                                line-height: 40px;
                                text-align: center;
                                cursor: pointer;
                            }
                        }
                    }
    
                    .right {
                        width: 48%;
                        img{
                            width: 100%;
                        }
                    }
                }
            }
    
            .case_dots {
                left: -16%;
                bottom: 10%;
    
                .slick-active {
                    button {
                        width: 8px;
                        height: 8px;
                        border-radius: 50%;
                        background-color: #C1272D;
                        border: 0;
                    }
                }
    
                li {
                    width: 16px !important;
    
                    button {
                        width: 8px;
                        height: 8px;
                        border: 1px solid #3C3C3C;
                        border-radius: 50%;
                    }
                }
            }
        }
    }
    
    .serve {
        margin-bottom: 76px;
        background-color: #fff;
        position: relative;
        height: 800px;
        .bg{
            width: 66%;
            height: 100%;
            background: #F5F7FA;
            border-radius: 220px 0px 0px 0px;
            position: absolute;
            top: 0;
            right: 0;
        }

        .content {
            width: 1200px;
            height: 100%;
            position: absolute;
            top: 100px;
            left: 50%;
            transform: translate(-50%, 0);

            .title {
                font-size: 48px;
                font-weight: bold;
                margin-bottom: 20px;
                .title-box{
                    position: relative;
                    .box{
                        display: flex;
                        justify-content: space-between;
                        position: absolute;
                        top: 24px;
                        left: -40px;
                        width: 18px;
                        height: 18px;
                        div{
                            width: 8px;
                            height: 8px;
                        }
                        div:nth-child(1){
                            background-color: #3C3C3C;
                        }
                        div:nth-child(2){
                            background-color: #C1272D;
                        }
                    }
                }
            }

            .title-s {
                font-size: 16px;
                color: #3C3C3C;
                display: block;
                padding-right: 6px;
            }


            ul {
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-wrap: wrap;
                padding-left: 212px;
                padding-right: 110px;

                li {
                    display: flex;
                    align-items: center;
                    margin-bottom: 60px;

                    .yuan {
                        width: 160px;
                        height: 160px;
                        background: #FFFFFF;
                        box-shadow: 0px 0px 16px 0px rgba(23, 96, 229, 0.08);
                        border-radius: 50%;
                        line-height: 160px;
                        text-align: center;
                        margin: 0 auto;
                        margin-right: 30px;
                    }

                    h2 {
                        font-size: 24px;
                        color: #C1272D;
                        margin-bottom: 12px;
                    }

                    p {
                        font-size: 16px;
                        line-height: 1.5;
                        width: 220px;
                    }
                }
            }

            .btn-box{
                display: flex;
                justify-content: flex-end;
                .btn {
                    width: 360px;
                    height: 66px;
                    background: #C1272D;
                    border-radius: 6px;
                    font-size: 18px;
                    color: #fff;
                    line-height: 66px;
                    text-align: center;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    position: relative;
    
                    img {
                        position: absolute;
                        top: 50%;
                        margin-top: -4px;
                        right: 20px;
                    }
                }
            }
            
        }
    }

    .raiders {
        margin-bottom: 78px;

        .content {
            width: 1200px;
            margin: 0 auto;
            .title {
                font-size: 48px;
                font-weight: bold;
                margin-bottom: 20px;
                .title-box{
                    position: relative;
                    .box{
                        display: flex;
                        justify-content: space-between;
                        position: absolute;
                        top: 24px;
                        left: -40px;
                        width: 18px;
                        height: 18px;
                        div{
                            width: 8px;
                            height: 8px;
                        }
                        div:nth-child(1){
                            background-color: #3C3C3C;
                        }
                        div:nth-child(2){
                            background-color: #C1272D;
                        }
                    }
                }
            }

            .title-s {
                font-size: 16px;
                color: #3C3C3C;
                display: block;
                padding-right: 6px;
            }

            ul {
                width: 100%;
                margin: 60px 0;
                height: 360px;
                display: flex;
                justify-content: space-between;
                li{
                    width: 18%;
                    height: 100%;
                    // background-image: url('./imgs/raiders-pic1.png');
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-position: 50% 0;
                    display: flex;
                    flex-direction: column-reverse;
                    cursor: pointer;
                    transition: .3s all;
                    .text-s{
                        text-align: center;
                        h2{
                            font-size: 24px;
                            color: #C1272D;
                            margin-bottom: 10px;
                            font-weight: bold;
                        }
                        p{
                            font-size: 18px;
                            color: #fff;
                            margin-bottom: 20px;
                        }
                    }
                    .text-b{
                        display: none;
                        padding:0 30px;
                        h2{
                            font-size: 28px;
                            color: #C1272D;
                            font-weight: bold;
                            margin-bottom: 18px;
                        }
                        h3{
                            font-size: 18px;
                            margin-bottom: 10px;
                        }
                        p{
                            font-size: 16px;
                            color: #666;
                            margin-bottom: 26px;
                            display:-webkit-box;  
                            -webkit-box-orient:vertical; 
                            -webkit-line-clamp:3;  
                            overflow:hidden;   
                        }
                    }
                }
                .big{
                    width: 36%;
                    height: 100%;
                    .text-s{
                        display: none;
                    }
                    .text-b{
                        display: block;
                    }
                }
            }
            .btn-box{
                display: flex;
                justify-content: flex-end;
                .btn {
                    width: 360px;
                    height: 66px;
                    background: #C1272D;
                    border-radius: 6px;
                    font-size: 18px;
                    color: #fff;
                    line-height: 66px;
                    text-align: center;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    position: relative;
    
                    img {
                        position: absolute;
                        top: 50%;
                        margin-top: -4px;
                        right: 20px;
                    }
                }
            }
        }
    }

    .news {
        margin-bottom: 78px;
        background-color: #fff;
        border-radius: 0px 0px 0px 220px;
        padding: 74px 0;

        .content {
            width: 1200px;
            margin: 0 auto;

            .title {
                font-size: 48px;
                font-weight: bold;
                margin-bottom: 20px;
                text-align: right;
                .title-box{
                    position: relative;
                    .box{
                        display: flex;
                        justify-content: space-between;
                        position: absolute;
                        top: 24px;
                        right: -40px;
                        width: 18px;
                        height: 18px;
                        div{
                            width: 8px;
                            height: 8px;
                        }
                        div:nth-child(1){
                            background-color: #3C3C3C;
                        }
                        div:nth-child(2){
                            background-color: #C1272D;
                        }
                    }
                }
            }

            .title-s {
                font-size: 16px;
                color: #3C3C3C;
                display: block;
                padding-right: 6px;
                text-align: right;
            }

            ul {
                padding-top: 30px;
                width: 100%;

                li {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    height: 166px;
                    margin-bottom: 10px;

                    .pic{
                        width: 19%;
                        height: 100%;
                        img{
                            width: 100%;
                            height: 100%;
                        }
                    }
                    .text{
                        width: 67%;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        border-bottom: 1px solid #E0E0E0;
                        .left {
                            width: 42%;
                            
    
                            h2 {
                                font-size: 20px;
                                font-weight: bold;
                                margin-bottom: 12px;
                            }
    
                            p {
                                font-size: 16px;
                                line-height: 1.25;
                                margin-bottom: 16px;
                                display: -webkit-box;
                                /**对象作为伸缩盒子模型展示**/
                                -webkit-box-orient: vertical;
                                /**设置或检索伸缩盒子对象的子元素的排列方式**/
                                -webkit-line-clamp: 2;
                                /**显示的行数**/
                                overflow: hidden;
                                /**隐藏超出的内容**/
                            }

                            .date {
                                font-size: 12px;
                                color: #999999;
                            }
                        }
    
                        .btn {
                            width: 46px;
                            height: 46px;
                            background: #C1272D;
                            border-radius: 50%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
    
                            img {
                                width: 22px;
                            }
                        }
                    }
                    
                }

                li:nth-child(1) .text{
                    border-top: 1px solid #E0E0E0;
                }
            }
        }
    }

    .distributed {
        padding-top: 80px;
        min-height: 800px;
        background-image: url('./imgs/map.png');
        background-size: 100%;
        background-repeat: no-repeat;

        .content {
            width: 1200px;
            margin: 0 auto;

            .title {
                font-size: 48px;
                font-weight: bold;
                margin-bottom: 20px;
                .title-box{
                    position: relative;
                    .box{
                        display: flex;
                        justify-content: space-between;
                        position: absolute;
                        top: 24px;
                        left: -40px;
                        width: 18px;
                        height: 18px;
                        div{
                            width: 8px;
                            height: 8px;
                        }
                        div:nth-child(1){
                            background-color: #3C3C3C;
                        }
                        div:nth-child(2){
                            background-color: #C1272D;
                        }
                    }
                }
            }

            .title-s {
                font-size: 16px;
                color: #3C3C3C;
                display: block;
                padding-right: 6px;
            }

            .number {
                margin-top: 46px;
                display: flex;
                justify-content: space-around;

                li {
                    text-align: center;
                    width: 33%;

                    h2 {
                        font-size: 48px;
                        font-weight: bold;
                        margin-bottom: 10px;
                    }

                    span {
                        color: #666666;
                        font-size: 16px;
                    }
                }

                li:nth-child(2) {
                    border-left: 2px solid #abbddf;
                    border-right: 2px solid #abbddf;
                }
            }
        }
    }
}

// 媒体查询
@media screen and (min-width: 992px) and (max-width: 1200px) {
    .home {
        .about{
            height: 640px;
            .content{
                width: 90%;
                .text{
                    .right{
                        padding-top: 30px;
                        .label{
                            margin-bottom: 40px;
                        }
                        h2{
                            margin-bottom: 30px;
                        }
                        p{
                            margin-bottom: 36px;
                        }
                    }
                }
            }
        }
        .advantage{
            .content{
                width: 90%;
                top: 160px;
                ul{
                    li{
                        .left{
                            h2{
                                font-size: 20px;
                            }
                            p{
                                font-size: 14px;
                            }
                            .btn{
                                width: 100px;
                                height: 30px;
                                line-height: 30px;
                                font-size: 14px;
                            }
                        }
                        img{
                            width: 48%;
                        }
                    }
                }
            }
        }
        .case-box{
            .case{
                width: 90%;
                margin-top: 160px;
                .caseItems {
                    padding: 60px 42px 80px 42px;
                    .caseItem {
                        .left{
                            width: 40%;
                            .title{
                                font-size: 40px;
                            }
                        }
                    }
                }
            }
        }
        .news{
            .content{
                width: 90%;
            }
        }
        .raiders{
            .content{
                width: 90%;
            }
        }
        .serve{
            .content{
                width: 90%;
                ul{
                    padding: 0;
                    margin-top: 20px;
                }
            }
        }
        .distributed{
            padding-top: 40px;
            min-height: 400px;
            .content{
                width: 90%;
                .number{
                    li{
                        h2{
                            font-size: 40px;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 576px) and (max-width: 992px) {
    .home {
        .about{
            height: 950px;
            .content{
                width: 90%;
                .title{
                    font-size: 40px;
                    text-align: center;
                }
                .title-s{
                    text-align: center;
                }
                .text{
                    flex-direction: column;
                    .left{
                        width: 100%;
                    }
                    .right{
                        width: 100%;
                        padding-top: 30px;
                        .label{
                            margin-bottom: 40px;
                        }
                        h2{
                            margin-bottom: 30px;
                            text-align: center;
                        }
                        p{
                            width: 100%;
                            margin-bottom: 36px;
                            text-align: center;
                        }
                        .btn{
                            margin: 0 auto;
                            margin-bottom: 20px;
                        }
                    }
                }
            }
        }
        .advantage{
            position: static;
            height: auto;
            margin-top: 60px;
            padding-top: 40px;
            padding-bottom: 40px;
            .content{
                width: 90%;
                top: 170px;
                position: static;
                transform: translate(0, 0);
                margin: 0 auto;
                .title{
                    font-size: 40px;
                    text-align: center;
                }
                .title-s{
                    text-align: center;
                }
                ul{
                    margin-top: 40px;
                    display: block;
                    padding-bottom: 40px;
                    margin-bottom: 0;
                    li{
                        position: inherit;
                        width: 80%;
                        margin: 0 auto;
                        padding: 30px;
                        margin-bottom: 20px;
                        .left{
                            h2{
                                font-size: 20px;
                            }
                            p{
                                font-size: 14px;
                            }
                            .btn{
                                width: 100px;
                                height: 30px;
                                line-height: 30px;
                                font-size: 14px;
                            }
                        }
                        img{
                            width: 48%;
                        }
                    }
                }
                .btn-box{
                    justify-content: center;
                }
            }
        }
        .case-box{
            padding-bottom: 0;
            background-image: none;
            .case{
                width: 90%;
                margin-top: 40px;
                .caseItems {
                    padding: 30px 42px 60px 42px;
                    .caseItem {
                        flex-direction: column-reverse;
                        .left{
                            width: 100%;
                            margin-bottom: 20px;
                            .title{
                                font-size: 40px;
                                text-align: center;
                            }
                            .title-s{
                                text-align: center;
                            }
                        }
                        .right{
                            width: 100%;
                            margin-bottom: 20px;
                        }
                    }
                }
                .case_dots{
                    left: 0;
                    bottom: 8%;
                }
            }
        }
        .news{
            margin-bottom: 0;
            .content{
                display: block;
                width: 90%;
                .title{
                    font-size: 40px;
                    text-align: center;
                }
                .title-s{
                    text-align: center;
                }
                ul{
                    width: 100%;
                    li{
                        .pic{
                            width: 28%;
                        }
                        .text{
                            .left{
                                width: 74%;
                            }
                        }
                    }
                }
            }
        }
        .raiders{
            .content{
                width: 90%;
                .title{
                    font-size: 40px;
                    text-align: center;
                }
                .title-s{
                    text-align: center;
                }
                ul{
                    flex-direction: column;
                    height: auto;
                    li{
                        width: 80%;
                        margin: 0 auto;
                        height: 500px;
                        margin-bottom: 20px;
                    }
                    li:nth-child(2){
                        border: 0;
                    }
                    .big{
                        width: 80%;
                        height: 500px;
                    }
                }
                .btn-box{
                    justify-content: center;
                }
            }
        }
        .serve{
            margin-bottom: 60px;
            height: 1000px;
            .content{
                width: 90%;
                .title{
                    font-size: 40px;
                    text-align: center;
                }
                .title-s{
                    text-align: center;
                }
                ul{
                    flex-wrap: wrap;
                    padding: 0;
                    li{
                        width: 90%;
                        margin-bottom: 20px;
                    }
                }
                .btn-box{
                    justify-content: center;
                }
            }
        }
        .distributed{
            padding-top: 40px;
            min-height: 220px;
            .content{
                width: 90%;
                .title{
                    font-size: 30px;
                    text-align: center;
                }
                .title-s{
                    text-align: center;
                }
                .number{
                    margin-top: 30px;
                    li{
                        h2{
                            font-size: 26px;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 576px) {
    .home {
        .about{
            height: auto;
            padding: 40px 0;
            .bg{
                display: none;
            }
            .content{
                position: static;
                width: 90%;
                margin: 0 auto;
                transform: none;
                .title{
                    font-size: 24px;
                    text-align: center;
                    .title-box{
                        .box{
                            top:14px;
                        }
                    }
                }
                .title-s{
                    text-align: center;
                }
                .text{
                    margin-top: 40px;
                    flex-direction: column;
                    .left{
                        width: 100%;
                    }
                    .right{
                        width: 100%;
                        padding-top: 30px;
                        .label{
                            margin-bottom: 40px;
                        }
                        h2{
                            margin-bottom: 30px;
                            text-align: center;
                        }
                        p{
                            width: 100%;
                            margin-bottom: 36px;
                            text-align: center;
                        }
                        .btn{
                            width: 80%;
                            height: 40px;
                            margin: 0 auto;
                            margin-bottom: 20px;
                        }
                    }
                }
            }
        }
        .advantage{
            border-radius: 0;
            position: static;
            height: auto;
            padding-top: 40px;
            padding-bottom: 40px;
            .content{
                width: 90%;
                top: 170px;
                position: static;
                transform: translate(0, 0);
                margin: 0 auto;
                .title{
                    font-size: 24px;
                    text-align: center;
                    .title-box{
                        .box{
                            top:14px;
                        }
                    }
                }
                .title-s{
                    text-align: center;
                }
                ul{
                    margin-top: 40px;
                    display: block;
                    padding-bottom: 40px;
                    margin-bottom: 0;
                    li{
                        position: inherit;
                        width: 80%;
                        margin: 0 auto;
                        padding: 30px;
                        margin-bottom: 20px;
                        .left{
                            h2{
                                font-size: 20px;
                            }
                            p{
                                font-size: 14px;
                            }
                            .btn{
                                width: 100px;
                                height: 30px;
                                line-height: 30px;
                                font-size: 14px;
                            }
                        }
                        img{
                            width: 48%;
                        }
                    }
                }
                .btn-box{
                    justify-content: center;
                    .btn{
                        width: 80%;
                        height: 40px;
                    }
                }
            }
        }
        .case-box{
            padding-bottom: 0;
            background-image: none;
            border-radius: 0;
            .case{
                width: 90%;
                .caseItems {
                    padding: 30px 42px 60px 42px;
                    .caseItem {
                        flex-direction: column-reverse;
                        .left{
                            width: 100%;
                            margin-bottom: 20px;
                            .title{
                                font-size: 24px;
                                text-align: center;
                                .title-box{
                                    .box{
                                        top:14px;
                                    }
                                }
                            }
                            .title-s{
                                text-align: center;
                            }
                            h2{
                                text-align: center;
                            }
                        }
                        .right{
                            width: 100%;
                            margin-bottom: 20px;
                        }
                    }
                }
                .case_dots{
                    left: 0;
                    bottom: 8%;
                }
            }
        }
        .serve{
            height: auto;
            padding: 40px 0;
            .bg{
                display: none;
            }
            .content{
                position: static;
                width: 90%;
                margin: 0 auto;
                transform: none;
                .title{
                    font-size: 24px;
                    text-align: center;
                    .title-box{
                        .box{
                            top:14px;
                        }
                    }
                }
                .title-s{
                    text-align: center;
                }
                ul{
                    flex-wrap: wrap;
                    padding: 0;
                    margin-top: 20px;
                    li{
                        width: 90%;
                        margin-bottom: 20px;
                        flex-direction: column;
                        .yuan{
                            margin: 0;
                            margin-bottom: 20px;
                        }
                        .text{
                            text-align: center;
                        }
                    }
                }
                .btn-box{
                    justify-content: center;
                    .btn{
                        width: 80%;
                        height: 40px;
                    }
                }
            }
        }
        .raiders{
            .content{
                width: 90%;
                .title{
                    font-size: 24px;
                    text-align: center;
                    .title-box{
                        .box{
                            top:14px;
                        }
                    }
                }
                .title-s{
                    text-align: center;
                }
                ul{
                    flex-direction: column;
                    height: auto;
                    li{
                        width: 80%;
                        margin: 0 auto;
                        height: 500px;
                        margin-bottom: 20px;
                    }
                    li:nth-child(2){
                        border: 0;
                    }
                    .big{
                        width: 80%;
                        height: 500px;
                    }
                }
                .btn-box{
                    justify-content: center;
                    .btn{
                        width: 80%;
                        height: 40px;
                    }
                }
            }
        }
        .news{
            margin-bottom: 0;
            border-radius: 0;
            padding: 40px 0;
            .content{
                display: block;
                width: 90%;
                .title{
                    font-size: 24px;
                    text-align: center;
                    .title-box{
                        .box{
                            top:14px;
                        }
                    }
                }
                .title-s{
                    text-align: center;
                }
                ul{
                    width: 100%;
                    li{
                        height: 130px;
                        .pic{
                            width: 28%;
                        }
                        .text{
                            .left{
                                width: 74%;
                                h2{
                                    font-size: 14px;
                                }
                                p{
                                    font-size: 12px;
                                }
                            }
                        }
                    }
                }
            }
        }
        .distributed{
            padding-top: 20px;
            min-height: 160px;
            .content{
                width: 90%;
                .title{
                    font-size: 20px;
                    text-align: center;
                    .title-box{
                        .box{
                            top:14px;
                        }
                    }
                }
                .title-s{
                    text-align: center;
                }
                .number{
                    margin-top: 20px;
                    li{
                        h2{
                            font-size: 20px;
                        }
                    }
                }
            }
        }
    }
}